@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.bg-custom-light {
  /*background-color: #e7e7e7;*/
  background-color: white;
}

.bg-dim {
  background-color: rgba(0, 0, 0, 0.6);
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-5 {
  opacity: 0.5;
}

.image-vertical-scroller {
  overflow-y: auto;
  max-height: 482px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.image-vertical-scroller img:not(:last-child) {
  margin-bottom: 10px;
}
.image-vertical-scroller img {
  background: rgb(233, 233, 233);
  cursor: pointer;
}

.image-vertical-scroller::-webkit-scrollbar {
  display: none;
}

.h-scroller {
  position: relative;
  z-index: 2;
  height: 3.75rem;
  overflow-y: hidden;
}

.h-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.h-underline .h-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.col-sm-3 {
  width: 25%;
}
.col-sm-9 {
  width: 75%;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #be0000;
}

ol.breadcrumb > li.breadcrumb-item > a {
  color: red;
}

ul.pagination > li {
  cursor: pointer;
}

.ta-left {
  text-align: left;
}

.input-group-lg .input-group-text {
  line-height: 2.5;
  height: 100%;
  min-width: 40px;
}

input.form-control {
  border: 1px solid #BBB;
}

.payment_methods {
  padding-top: 10px;
}

.payment_methods > img {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
  margin: 5px;
  height: 25px;
  width: 40px;
  vertical-align: middle;
}

ol.breadcrumb > li.active {
  color: #000;
}
ol.breadcrumb > li.active > a {
  color: #000;
}

.w-60 {
  width: 60%;
}

.carousel-indicators > button {
  background-color: white !important;
  height: 5px !important;
  opacity: 0.8 !important;
}
.carousel-indicators > button.active {
  background-color: orangered !important;
}

.carousel-caption > * {
  background-color: rgba(255, 0, 0, 0.6);
  padding: 5px;
  display: inline-block;
  font-weight: bold;
}

ul.navbar-nav > li.nav-item > .nav-link {
  font-weight: bold;
  color: #777;
}

ul.navbar-nav > li.active > .nav-link {
  color: #be0000;
}

.service-icon {
  background: radial-gradient(circle, #444, #999, #AAA);;
}

.scroll-to-top-button {
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: 20px;
 }

.form-floating > label {
  left: 10px;
}

.ta-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.bg-grey {
  background-color: #e5e5e5;
}

h6 {
  font-size: 1.1rem;
}

h7 {
  font-size: 1.0rem;
  font-weight: bold;
}

h7 > a:hover {
  color: #be0000;
}

.visible {
  opacity: 1 !important;;
  transform: none;
  visibility: visible;
  display: flex;
}

section.animate {
  padding: 0 !important;;
  margin: 0 !important;
  box-shadow: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1500ms ease-out, transform 1500ms ease-out;
  will-change: opacity, visibility;
}
